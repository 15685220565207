<div class="relative w-full top-0 h-[70px] bg-gray-900"></div>
<div *ngIf="machine" [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="w-full relative lg:flex py-1 bg-[#2F3644] z-10">
    <span class="w-full lg:w-5/12 xl:w-1/3 grid grid-cols-3 lg:inline-flex rounded-md shadow-sm gap-1 z-10">
      <button
        type="button"
        (click)="getElToViewPortFun('specific')"
        class="relative inline-flex h-9 items-center rounded-l-md bg-gray-600 px-3 text-sm font-semibold text-white ring-gray-300 hover:bg-[#e60027] focus:z-10">
        {{ "simulation" | transloco }}
      </button>
      <button
        type="button"
        (click)="getElToViewPortFun('over')"
        class="relative inline-flex h-9 items-center bg-gray-600 px-3 text-sm font-semibold text-white ring-gray-300 hover:bg-[#e60027] focus:z-10">
        {{ "machineOverview" | transloco }}
      </button>
      <button
        type="button"
        (click)="openViewerDialog()"
        class="relative inline-flex h-9 items-center bg-gray-600 px-3 text-sm font-semibold text-white ring-gray-300 hover:bg-[#e60027] focus:z-10">
        <img class="mx-auto w-8 mt-1" src="assets/home/whiteDegrees.svg" />
      </button>
      <button
        type="button"
        (click)="getElToViewPortFun('product')"
        class="relative inline-flex h-9 items-center bg-gray-600 px-3 text-sm font-semibold text-white ring-gray-300 hover:bg-[#e60027] focus:z-10">
        {{ "availableProduct" | transloco }}
      </button>
      <button
        type="button"
        (click)="getElToViewPortFun('yVideos')"
        class="relative inline-flex h-9 items-center rounded-r-md bg-gray-600 px-3 text-sm font-semibold text-white ring-gray-300 hover:bg-[#e60027] focus:z-10">
        {{ "videos" | transloco }}
      </button>
    </span>
    <div class="hidden lg:block w-1/3">
      <h1 class="text-5xl md:text-5xl lg:text-5xl xl:text-5xl text-center noMrBottom">
        {{ machine.name[activeLang] }}
      </h1>
    </div>

    <div class="hidden lg:block w-1/3 text-white">
      <div class="flex items-center justify-center">
        <i class="far fa-eye mr-1"></i> <span> {{ machine.visits }} </span>
      </div>
    </div>
    <div class="block lg:hidden mt-2">
      <div class="flex">
        <div class="w-10/12">
          <p class="text-lg md:text-5xl lg:text-5xl xl:text-5xl text-center noMrBottom">
            {{ machine.name[activeLang] }}
          </p>
        </div>
        <div class="w-2/12 text-white flex items-center justify-center">
          <i class="far fa-eye mr-1"></i> <span> {{ machine.visits }} </span>
        </div>
      </div>
    </div>
  </div>

  <div class="px-2 lg:px-10 lg:flex mt-20 max-md:mt-32">
    <div class="w-full lg:w-1/3 lg:pr-5">
      <img *ngIf="machine.images[0]?.url !== 'none'" width="100%" [src]="machine.images[0]?.url" loading="lazy" alt="" />
    </div>
    <div class="w-full lg:w-2/3 bg-zinc-100 max-lg:mt-5 rounded-xl p-3">
      <div [innerHTML]="machine.description[activeLang] | safeHtml" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'"></div>
    </div>
  </div>
  <div class="w-full flex justify-center mt-16">
    <button type="button" (click)="getElToViewPortFun('contactUs')" class="bg-[#e60027] font-semibold text-white py-2 px-4 border border-[#e60027] rounded">
      {{ "contactUsNow" | transloco }}
    </button>
  </div>
  <div class="flex items-center justify-center mt-32">
    <p class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full">
      {{ "specifications" | transloco }}
    </p>
  </div>
  <div class="grid grid-cols-1 lg:grid-cols-2 items-center gap-10 px-3 lg:px-24 mt-10" id="specific" #specific>
    <div class="w-full">
      <img [src]="machine.simulation?.url" loading="lazy" class="mx-auto" width="50%" alt="GIF File" />
    </div>
    <div class="w-full">
      <table class="table-auto border-collapse border border-gray-400 w-full text-left">
        <thead class="bg-gray-200">
          <tr>
            <th class="border border-gray-300 px-4 py-2">Item</th>
            <ng-container>
              <th class="border border-gray-300 px-4 py-2">MRZK100Series</th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rowGroup of specifications; let i = index">
            <td class="border border-gray-300 px-2 py-2 text-nowrap font-medium">
              {{ rowGroup }}
            </td>
            <td class="border border-gray-300 px-2 py-2">
              {{ machine.tableInfo[i].MRZK100Series }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="flex items-center justify-center mt-36">
    <p class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full">
      {{ "machineOverview" | transloco }}
    </p>
  </div>
  <div class="mt-16 px-1 lg:px-10 w-full xl:w-1/2 mx-auto" id="over" #over>
    <div [innerHTML]="svgContent"></div>
    <div #floatingBox class="floating-box" *ngIf="showFloatingBox" [style.top]="boxPosition.y + 'px'" [style.left]="boxPosition.x + 'px'">
      {{ floatingBoxContent }}
    </div>
  </div>
  <div class="flex items-center justify-center mt-20">
    <p class="text-center inline-block text-lg lg:text-xl border border-black px-5 py-1 rounded-full">
      {{ "machineCOverview" | transloco }}
    </p>
  </div>
  <div *ngIf="SVGParts.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 px-3 lg:px-20 mt-24">
    <div class="bg-[#dedede17] shadow-md p-4 rounded-xl grid content-between" *ngFor="let part of SVGParts; let i = index" [attr.id]="'p_' + part?._id" #dynamicRef>
      <div class="w-64 h-64 mx-auto overflow-hidden">
        <img *ngIf="part?.image.url !== 'none'" loading="lazy" [src]="part?.image.url" alt="Image 1" class="center object-contain w-full h-full" />
      </div>
      <h2>
        {{ part?.title[activeLang] }}
      </h2>
      <p class="inline-block" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
        {{ part?.description[activeLang] | truncate : 20 }}
      </p>
      <div class="flex items-center justify-center">
        <a
          (click)="sendData(i)"
          [routerLink]="['/', activeLang, 'part_overview', machine._id, part?.title[activeLang]]"
          type="button"
          class="rounded-md text-center w-32 bg-[#E60027] mt-4 px-2 py-1 text-sm text-white shadow-sm hover:bg-[#D40024]">
          {{ "learnMore" | transloco }}
        </a>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-center mt-36">
    <p class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full">
      {{ "availableProduct" | transloco }}
    </p>
  </div>
  <div class="flex flex-wrap justify-center px-5" id="product" #product>
    <a
      class="w-1/3 lg:w-1/12 mx-4 mt-5 p-2 rounded-lg border border-dashed border-[#E60027] cursor-pointer"
      *ngFor="let product of machine.productsIDS"
      [matTooltip]="product.title[activeLang]">
      <img *ngIf="product.documents[0]?.url !== 'none'" [src]="product.documents[0]?.url" width="100%" alt="Product" loading="lazy" />
    </a>
  </div>
  <div class="flex items-center justify-center mt-36">
    <p class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full">
      {{ "availableBags" | transloco }}
    </p>
  </div>
  <div class="flex flex-wrap justify-center px-5">
    <a
      class="w-1/3 lg:w-1/12 mx-4 mt-5 p-2 rounded-lg border border-dashed border-[#E60027] cursor-pointer"
      *ngFor="let bag of machine.bagsIDS"
      [matTooltip]="bag.title[activeLang]">
      <img *ngIf="bag.documents[0]?.url !== 'none'" [src]="bag.documents[0]?.url" width="100%" alt="Bag" loading="lazy" />
    </a>
  </div>

  <div class="flex items-center justify-center mt-36">
    <p class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full">
      {{ "youtubeVideos" | transloco }}
    </p>
  </div>
  <div *ngIf="saveURLSDone" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 lg:gap-10 justify-around px-5" id="yVideos" #yVideos>
    <div *ngFor="let youtubeItem of machine.youTubeVideos">
      <iframe
        class="mt-10"
        width="100%"
        height="100%"
        [src]="youtubeItem.url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; 
        encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>
  </div>

  <div class="flex items-center justify-center mt-36">
    <p class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full">
      {{ "spicialVideos" | transloco }}
    </p>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 lg:gap-10 justify-around px-5 mt-10 z-0">
    <div class="z-0" *ngFor="let videoItem of machine.serverVideos">
      <video class="z-0" controls preload="metadata">
        <source [src]="videoItem.url" type="video/mp4" loading="lazy" />
      </video>
    </div>
  </div>
  <!-- For Users *************** -->
  <div *ngIf="user">
    <div class="flex items-center justify-center mt-36">
      <p class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full">
        {{ "electricalDiagrams" | transloco }}
      </p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 lg:gap-10 justify-around px-5 mt-10">
      <div class="bg-zinc-100 p-2 rounded-lg" *ngFor="let pdfItem of machine.electricityDiagram">
        <div class="grid justify-center">
          <p class="text-center font-semibold">
            {{ pdfItem?.filename }}
          </p>
          <a class="cursor-pointer mainColor text-center" [href]="pdfItem?.url" target="_blank">View</a>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-20" id="contactUs" #contactUs>
    <app-send-offer-req [machineId]="machine._id" [machineName]="machine.name['EN']"></app-send-offer-req>
  </div>
  <div *ngIf="suggestsMachines"></div>
  <div class="flex items-center justify-center my-20">
    <p class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full">
      {{ "suggestedMachines" | transloco }}
    </p>
  </div>
  <div class="px-1 lg:px-10 grid grid-cols-2 xl:grid-cols-4 items-center gap-4">
    <div class="flex mb-10" *ngFor="let item of suggestsMachines">
      <div class="w-1/2 pr-4">
        <a [routerLink]="['/', activeLang, 'machine', item.seoURL]">
          <img *ngIf="item.images[0].url !== 'none'" [src]="item.images[0]?.url" loading="lazy" width="100%" [alt]="item.altText" />
        </a>
      </div>
      <div class="w-1/2">
        <a class="hover:text-[#E60027]" [routerLink]="['/', activeLang, 'machine', item.seoURL]">
          <h2 class="mt-2">
            {{ item.name[activeLang] }}
          </h2>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="my-60"></div>
