import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Meta, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { GeneralService } from 'app/services/general.service';
import { HreflangService } from 'app/services/hreflang.service';
import { SharedDataService } from 'app/services/sharedData.service';
import { Machine } from 'app/shared/machine';
import { ThreeDViewerDialogComponent } from 'app/three-dviewer-dialog/three-dviewer-dialog';
import { UserService } from 'app/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  machine: Machine;
  user: any = undefined;
  suggestsMachines: any[] = [];
  specifications: any[] = [];
  svgContent: SafeHtml = '';
  showFloatingBox: boolean = false;
  saveURLSDone: boolean = false;
  floatingBoxContent: string = '';
  boxPosition: { x: number; y: number } = { x: 0, y: 0 };
  SVGParts: any[] = [];
  activeLang: string = 'EN';
  scriptElement;
  private langChangesSubscription: Subscription;
  private dataSubscription: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private sanitizer: DomSanitizer,
    private elRef: ElementRef,
    private gServices: GeneralService,
    private _translocoService: TranslocoService,
    private http: HttpClient,
    public router: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private renderer: Renderer2,
    private hreflangService: HreflangService,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private _userService: UserService,
  ) {

  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.getMachine();
      this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
        this.activeLang = activeLang;
        this.translateSpecifications(activeLang)
      });
      this.suggestsMachines = JSON.parse(localStorage.getItem('selectedMachines'));
      this._userService.user$.subscribe((res) => {
        this.user = res;
      })
    }

  }

  getMachine() {
    this.dataSubscription = this.router.data.subscribe((data: any) => {
      if (data.machine) {
        this.machine = data.machine;
        this.SVGParts = data.machine.partes;
        if (isPlatformBrowser(this.platformId)) {
          this.fetchSvg();
          this.getSaveURL();
          this.increaseVisitsFun();
          this.hreflangService.updateHreflangTags(this.activeLang, `/product/${this.machine._id}/${this.machine.seoURL}`);
          this.addProductSchema(
            {
              image: this.machine.images[0].url,
              name: this.machine.name[this.activeLang],
              description: this.machine.description[this.activeLang],
              offerUrl: `https://zirvecompany.net/${this.activeLang}/product/${this.machine._id}/${this.machine.seoURL}`
            });
        }

      }
    });
  }

  // setDescriptionToSave() {
  //   return this.sanitizer.bypassSecurityTrustHtml(this.machine.description[this.activeLang]);
  // }

  increaseVisitsFun(): void {
    const isOldVisitor = sessionStorage.getItem('visitor');
    if (!isOldVisitor) {
      this.gServices.getItem("machine/visit", this.machine._id).subscribe();
      sessionStorage.setItem('visitor', '1')
    }
  }

  fetchSvg() {
    if (this.machine.svgFile.url !== 'none') {
      this.http.get(this.machine.svgFile.url, { responseType: 'text' }).subscribe(svgData => {
        this.svgContent = this.sanitizer.bypassSecurityTrustHtml(svgData);
        setTimeout(() => this.addHoverEffect(), 0);
      });
    }

  }

  addHoverEffect() {
    if (isPlatformBrowser(this.platformId)) {
      const svgParts = this.SVGParts;
      const scrollToItem = (itemId: string) => {
        const targetElement = document.getElementById('p_' + itemId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
          // Add glow effect after scrolling
          targetElement.classList.add('selectedEffect');
          setTimeout(() => {
            targetElement.classList.remove('selectedEffect');
          }, 3000); // Duration of the scroll animation
        }
      };
      const handleClick = (g: Element) => {
        const clickedId = g.getAttribute('id');
        scrollToItem(clickedId);
      };

      const gElements = this.elRef.nativeElement.querySelectorAll('svg g');
      gElements.forEach((g: Element) => {

        this.renderer.listen(g, 'click', () => handleClick(g));
        this.renderer.listen(g, 'mouseenter', (event) => {
          this.showFloatingBox = true;
          const hoveredId = g.getAttribute('id');
          const hoveredItem = svgParts.find(item => item._id === hoveredId);
          this.floatingBoxContent = hoveredItem ? hoveredItem.description[this.activeLang] : '';
          this.updateBoxPosition(event);
          this.renderer.removeClass(g, 'st0');
          this.renderer.addClass(g, 'hover-effect');
          this._changeDetectorRef.markForCheck();
        });
        this.renderer.listen(g, 'mousemove', (event) => {
          this.updateBoxPosition(event);
        });
        this.renderer.listen(g, 'mouseleave', () => {
          this.showFloatingBox = false;
          this.renderer.removeClass(g, 'hover-effect');
          this.renderer.addClass(g, 'st0');
          this._changeDetectorRef.markForCheck();
        });


      });
    }
  }


  getElToViewPortFun(itemId) {
    const targetElement = document.getElementById(itemId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }

  updateBoxPosition(event: MouseEvent) {
    this.boxPosition.x = event.clientX + 10; // Offset to prevent cursor overlap
    this.boxPosition.y = event.clientY + 10;
  }

  sendData(index) {
    this.sharedDataService.setSelectedProduct(this.SVGParts[index]);
  }

  ngOnDestroy(): void {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.scriptElement) {
      this.renderer.removeChild(document.head, this.scriptElement);
      this.scriptElement = null;
    }
  }

  getSaveURL() {
    this.machine.youTubeVideos.forEach((item: any) => {
      item.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${item.url}`);
    })
    setTimeout(() => {
      this.saveURLSDone = true;
    }, 2000);
  }

  addProductSchema(productData: any) {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Product",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "reviewCount": "89"
      },
      "description": productData.description,
      "name": productData.name,
      "image": productData.image,
      "offers": {
        "@type": "Offer",
        "availability": "https://schema.org/InStock",
        "price": "55.00",
        "lowPrice": "54.00",
        "priceCurrency": "USD"
      },
      "review": [
        {
          "@type": "Review",
          "author": "Hazem",
          "datePublished": "2018-04-01",
          "reviewBody": "Good Product",
          "name": "Happy",
          "reviewRating": {
            "@type": "Rating",
            "bestRating": "5",
            "ratingValue": "5",
            "worstRating": "5"
          }
        }
      ]
    });
    this.scriptElement = script;
    this.renderer.appendChild(document.head, script);
  }

  translateSpecifications(activeLang): void {
    switch (activeLang) {
      case 'AR':
        this.specifications = ['نظام التعبئة', 'المواد', 'نظام العمل', 'وزن التعبئة', 'سرعة التعبئة', 'عرض العبوة', 'استهلاك الطاقة'];
        break;
      case 'TR':
        this.specifications = ['Paketleme sistemi', 'Malzemeler', 'Çalışma sistemi', 'Ambalaj ağırlığı', 'Doldurma hızı', 'Paket genişliği', 'Enerji tüketimi'];
        break;
      case 'FR':
        this.specifications = ['Système demballage', 'Matériaux', 'Système de travail', 'Poids demballage', 'Vitesse de remplissage', 'Largeur du colis', 'Consommation dénergie'];
        break;
      case 'RU':
        this.specifications = ['Система упаковки', 'Материалы', 'Система работы', 'Вес упаковки', 'Скорость наполнения', 'Ширина упаковки', 'Потребление энергии'];
        break;
      default:
        this.specifications = ['Packing system', 'Materials', 'Work system', 'Packing weight', 'Filling speed', 'Package width', 'Energy consumption'];
        break;
    }
  }
  openViewerDialog(): void {
    const dialogRef = this.dialog.open(ThreeDViewerDialogComponent, {
      data: {
        images: this.machine.threeDView.map(el => el?.url),
        folderName: this.machine.folderName,
        routeName: 'machine',
        imgWidth: 'w-5/6',
      },
      width: '95rem',
      height: '42rem',
    });
    dialogRef.afterClosed().subscribe();
  }

  // getSafeDescription() {
  //   console.log(this.sanitizer.bypassSecurityTrustHtml(this.machine.description[this.activeLang]));

  //   return this.sanitizer.bypassSecurityTrustHtml(this.machine.description[this.activeLang]);
  // }

}